<div class="
  text-[16px]/[24px] text-text font-bold
  [&_h2]:text-[22px]/[27px]
  [&_h3]:text-[20px]/[26px]
  [&_h4]:text-[28px]/[24px]
  [&_h5]:text-[16px]/[22px]
  [&>*:not(:first-child)]:mt-4 [&_p]:font-medium
  [&_a]:underline
">
  <slot />
</div>